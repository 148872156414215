<template>
  <div>
    <el-card class="box-card">
      <div class="search-table-box">
        <div>
          <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
            <el-form-item label="积分体系">
              <el-select v-model="queryParams.systemName" placeholder="请选择" class="w230" clearable>
                <el-option :label="item.systemName" :value="item.systemName" v-for="item in integralSystemList" :key="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
            <div class="down-export">
              <el-button type="primary" @click="add()">新增</el-button>
            </div>
          </el-form>
        </div>
        <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border
          :header-cell-style="headerCellStyle">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="systemName" label="体系名称" align="center" />
          <el-table-column prop="createTime" label="体系生成日期" align="center" />
          <el-table-column label="操作" width="250" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
              <el-button type="text" @click="changeStatusDialog(scope.row)" v-if="scope.row.state==='0'">启用</el-button>
              <el-button type="text" @click="changeStatusDialog(scope.row)" class="red" v-if="scope.row.state==='1'">停用
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getIntegralSystemList,
  updateIntegralSystem,
  getIntegralRecordList
} from '@/api/integralSystem'

export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      queryParams: {
        //查询参数
        pageNum: 1,
        pageSize: 10,
        systemName: ''
      },
      total: 0,
      integralSystemList: []
    }
  },
  mounted() {
    this.getList()
    this.getIntegralRecordList()
  },
  methods: {
    async getIntegralRecordList() {
      const res = await getIntegralRecordList()
      this.integralSystemList = res.data
    },
    async getList() {
      this.loading = true
      const res = await getIntegralSystemList(this.queryParams)
      this.loading = false
      this.tableData = res.data.rows
      this.total = res.data.total
    },
    add() {
      this.$router.push({
        path: '/volunteer/integralSystem/add',
        query: { type: 1 }
      })
    },
    edit(row) {
      this.$router.push({
        path: '/volunteer/integralSystem/edit',
        query: { info: JSON.stringify(row), type: 2 }
      })
    },
    toDetail(row) {
      this.$router.push({
        path: '/volunteer/integralSystem/details',
        query: { info: JSON.stringify(row), type: 3 }
      })
    },
    async changeStatus(row) {
      const state = row.state === '1' ? '0' : '1'
      const res = await updateIntegralSystem({ id: row.id, state: state })
      row.state = state
      this.$message.success(res.data)
    },
    changeStatusDialog(row) {
      // 状态 0 禁用 1 启用
      let msg = ''
      if (row.state === '0') {
        msg = `是否启用${row.systemName}？`
      } else {
        msg = `是否停用${row.systemName}？`
      }
      this.$confirm(`${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeStatus(row)
      })
    },
    searchList() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  min-height: calc(100% - 10px);
}
.red {
  color: rgb(255, 90, 89);
}
.search-table-box {
  .down-export {
    float: right;
    margin-bottom: 20px;
    display: flex;
    button {
      margin-left: 10px;
    }
    button:first-child {
      margin: 0 10px;
    }
  }
}
/deep/ .upload-demo {
  text-align: center;
}
</style>
